<script setup lang="ts">
import { BaseButton, BaseIcon, BaseTabs } from '@legacy-studio/library';
import {
	useChartIndicatorItems,
	useAddChartIndicatorItem,
	type ChartIndicatior,
} from '@/src/features/terminal';
import { useRemoveIndicatorById } from '~/src/features/terminal/core/application';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const addChartIndicatorItem = useAddChartIndicatorItem();
const currentIndicatorItems = useChartIndicatorItems();

const activeTab = ref('list');

const tabs = [
	{
		code: 'list',
		name: 'Инструменты',
		icon: 'pencil',
	},
	{
		code: 'added',
		name: 'Добавленные',
		icon: 'collection',
	},
];

const allIndicatorItems: {
	text: string;
	value: ChartIndicatior;
}[] = [
	{
		text: 'SSMA',
		value: 'Smoothed Moving Average',
	},
	{
		text: 'LSMA',
		value: 'Least Squares Moving Average',
	},
	{
		text: 'EMA',
		value: 'Moving Average Exponential',
	},
	{
		text: 'SAR',
		value: 'Parabolic SAR',
	},
	{
		text: 'Алли Гатор',
		value: 'Williams Alligator',
	},
	{
		text: 'Линии Боллинджера',
		value: 'Bollinger Bands',
	},
	{
		text: 'RSI',
		value: 'Relative Strength Index',
	},
	{
		text: 'CCI',
		value: 'Commodity Channel Index',
	},
	{
		text: 'Stochastic',
		value: 'Stochastic',
	},
];
const sidebarStore = useSidebarStore();
const onChange = (item: {
	text: string;
	value: ChartIndicatior;
}) => {
	sidebarStore.closeDrawer();
	addChartIndicatorItem(item);
};
const onDelete = (button: any) => {
	useRemoveIndicatorById()(button.id);
};
</script>

<template>
	<div class="flex flex-col gap-4">
		<BaseTabs
			v-model="activeTab"
			:items="tabs"
		/>

		<div
			v-if="activeTab === 'list'"
			class="flex flex-col gap-4"
		>
			<BaseButton
				v-for="button in allIndicatorItems"
				:key="button.value"
				color="alternative-dark"
				size="sm"
				outline
				:button-text="button.text"
				@click="onChange(button)"
			/>
		</div>

		<div
			v-else
			class="text-white"
		>
			<div
				v-for="button in currentIndicatorItems"
				:key="button.value"
				class="flex justify-between items-center py-3 bg-gray-800 hover:bg-gray-700 border-b border-gray-600 gap-3"
			>
				<div class="flex items-center gap-3">
					<span class="text-white text-base font-medium">{{ button.text }}</span>
				</div>

				<div class="flex items-center gap-3">
					<BaseIcon
						name="base--stroke--trash"
						class="text-white text-[24px] cursor-pointer"
						@click="onDelete(button)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
